import React from "react";

import PdfHtmlBox from "../components/PdfHtmlBox";

export default function PdfTestataTpl({ item }) {
  return (
    <div
      is="page"
      aria-size="A4"
      //

      style={{
        position: "relative",
        minHeight: "1000px",
      }}
    >
      {item.contenuti.map((e, k) => {
        let style = {};
        switch (k) {
          case 0:
            return (
              <div key={k}>
                <div
                  style={{
                    position: "absolute",
                    left: "50px",
                    top: "150px",

                    float: "right",
                    zIndex: 10,
                    background: "none",
                    fontWeight: "bold",
                  }}
                >
                  <PdfHtmlBox
                    className="titolo"
                    contenuto={e}
                    editor={true}
                    chiave={k}
                    key={k}
                    style={style}
                  />
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
            );
          case 1:
            return (
              <img
                //className="immagine"
                src={e.valore}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  width: "100%",
                  zIndex: 0,
                }}
              />
            );
          case 2:
            return (
              <img
                //className="immagine"
                chiave={2}
                src={e.valore}
                style={{
                  position: "absolute",
                  top: 450,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  width: "100%",
                  //height: "100%",
                  zIndex: 0,
                  background: "#fff",
                  //aspectRatio: "16/9",
                }}
              />
            );
          case 4:
            return (
              <div
                style={{
                  position: "absolute",
                  top: "400px",
                  background: "transparent",
                  width: "99%",

                  textAlign: "left",
                  fontWeight: 400,
                  paddingBottom: 15,
                }}
              >
                <PdfHtmlBox
                  contenuto={e}
                  editor={true}
                  chiave={k}
                  key={k}
                  style={style}
                />
              </div>
            );
          case 5:
            return (
              <div
                style={{
                  position: "absolute",
                  top: "150px",
                  right: "10px",
                  // border: "1px solid #000",
                  background: "transparent",
                  width: "30%",
                  zIndex: "1000",
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                <PdfHtmlBox
                  contenuto={e}
                  editor={true}
                  chiave={k}
                  key={k}
                  style={style}
                />
              </div>
            );

          default:
            return (
              <div
                style={{
                  position: "absolute",
                  bottom: "100px",
                  background: "transparent",
                  width: "500px",
                }}
              >
                <PdfHtmlBox
                  contenuto={e}
                  editor={false}
                  chiave={k}
                  key={k}
                  style={{ background: "none" }}
                />
              </div>
            );
        }
      })}
    </div>
  );
}
