import React from "react";

import PdfHtmlBox from "../components/PdfHtmlBox";

import "react-tiny-fab/dist/styles.css";

export default function PdfPaginaProdottoTpl({ item }) {
  return (
    <div is="page" aria-size="A4">
      <div className="tabella-prodotto">
        <div
          className="colonna-sx"
          style={{ position: "relative", paddingTop: 35 }}
        >
          <p
            className=""
            style={{
              //border: "1px solid #000",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              //border: "none",
              borderBottom: "1px solid #000",
              background: "#eee",
              fontWeight: "bold",
            }}
          >
            Tipologia
          </p>
          {
            <PdfHtmlBox
              className="titolo"
              contenuto={item.contenuti[0]}
              editor={false}
              chiave={0}
            />
          }
          <img className="immagine" src={item.contenuti[1].valore} />
        </div>
        <div
          className="colonna-dx"
          style={{ position: "relative", paddingTop: 35 }}
        >
          <p
            className=""
            style={{
              //border: "1px solid #000",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              //border: "none",
              borderBottom: "1px solid #000",
              background: "#eee",
              fontWeight: "bold",
            }}
          >
            Descrizione
          </p>
          <PdfHtmlBox
            className="descrizione-breve"
            contenuto={item.contenuti[2]}
            chiave={2}
          />
          <PdfHtmlBox
            className="descrizione-riepilogo-Economico"
            contenuto={item.contenuti[3]}
            chiave={3}
          />
          <PdfHtmlBox
            className="posizione"
            contenuto={item.contenuti[4]}
            chiave={4}
          />

          <PdfHtmlBox
            className="descrizione"
            contenuto={item.contenuti[5]}
            chiave={5}
          />
          <PdfHtmlBox
            className="tipologia"
            contenuto={item.contenuti[6]}
            chiave={6}
          />
          <PdfHtmlBox
            className="dimensioni dettagli"
            contenuto={item.contenuti[7]}
            chiave={7}
          />
        </div>
      </div>
      <img src="footer.png" style={{ width: "100%" }}></img>
    </div>
  );
}
