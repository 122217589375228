import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
//import counterReducer from "../features/counter/counterSlice";
import blueprintReducer from "../features/paragrafi/blueprintSlice";
import entityReducer from "../features/paragrafi/entitySlice";
import paginaReducer from "../features/pagina/paginaSlice";
import { apiSlice } from "../features/api/apiSlice";
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    //counter: counterReducer,
    blueprint: blueprintReducer,
    entity: entityReducer,
    pagina: paginaReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
//setupListeners(store.dispatch);
