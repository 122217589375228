import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  increment,
  decrement,
  selectPagina,
} from "../features/pagina/paginaSlice";
import { selectPageCount } from "../features/paragrafi/entitySlice";

export default function FrecceNavigazione() {
  const dispatch = useDispatch();
  const { pageIndex } = useSelector(selectPagina);
  const pageCount = useSelector(selectPageCount);
  //console.log(pageCount);
  return (
    <>
      <div
        style={{
          position: "sticky",
          left: 10,
          top: "calc(50% - 25px)",
          fontSize: 50,
          width: 50,
          cursor: "pointer",
          opacity: pageIndex == 0 ? "0.2" : "1",
        }}
        onClick={() => {
          if (pageIndex > 0) dispatch(decrement());
        }}
      >
        <i className="fa fa-chevron-left"></i>
      </div>
      <div
        style={{
          position: "sticky",
          left: "calc(100% - 50px)",
          top: "calc(50% - 25px)",
          fontSize: 50,
          width: 50,
          cursor: "pointer",
          opacity: pageIndex - pageCount == -1 ? "0.2" : "1",
        }}
        onClick={() => {
          if (pageIndex < pageCount - 1) dispatch(increment());
        }}
      >
        <i className="fa fa-chevron-right"></i>
      </div>
    </>
  );
}
