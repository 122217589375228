import React from "react";

import PdfHtmlBox from "../components/PdfHtmlBox";
import RigheTabella from "../components/RigheTabella";
import "react-tiny-fab/dist/styles.css";

export default function PdfRiepilogoEconomico({ item }) {
  return (
    <div is="page" aria-size="A4">
      <div className="tabella-prodotto">
        <div style={{ width: "100%" }}>
          <table style={{ width: "100%" }} border={1}>
            <thead>
              <tr>
                <th colSpan="4">
                  <PdfHtmlBox
                    className="titolo"
                    contenuto={item.contenuti[0]}
                  />
                  <br />
                </th>
              </tr>
              <tr>
                <td style={{ backgroundColor: "#eee" }}>
                  <PdfHtmlBox
                    className="intestazione-colonna"
                    contenuto={item.contenuti[1]}
                  />
                </td>
                <td style={{ backgroundColor: "#eee" }}>
                  <PdfHtmlBox
                    className="intestazione-colonna"
                    contenuto={item.contenuti[2]}
                  />
                </td>
                <td style={{ backgroundColor: "#eee" }}>
                  <PdfHtmlBox
                    className="intestazione-colonna"
                    contenuto={item.contenuti[3]}
                  />
                </td>
                <td>
                  <PdfHtmlBox
                    className="intestazione-colonna"
                    contenuto={item.contenuti[4]}
                  />
                </td>
              </tr>

              <RigheTabella
                className="intestazione-colonna"
                contenuto={item.contenuti[5]}
              ></RigheTabella>
              <tr>
                <td
                  colSpan="3"
                  style={{ backgroundColor: "#000", color: "#fff" }}
                >
                  <PdfHtmlBox
                    className="intestazione-colonna"
                    contenuto={item.contenuti[6]}
                  />
                </td>
                <td
                  colSpan="1"
                  style={{ backgroundColor: "#000", color: "#fff" }}
                >
                  <PdfHtmlBox
                    className="intestazione-colonna"
                    contenuto={item.contenuti[7]}
                  />
                </td>
              </tr>
            </thead>
          </table>
          <div>
            <PdfHtmlBox
              className="intestazione-colonna"
              contenuto={item.contenuti[8]}
            />
          </div>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td>
                  <PdfHtmlBox
                    className="intestazione-colonna"
                    contenuto={item.contenuti[9]}
                  />
                </td>
                <td>
                  {/*uguale alla somma della tabella*/}
                  <PdfHtmlBox
                    className="intestazione-colonna"
                    contenuto={item.contenuti[7]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <img src="footer.png" style={{ width: "100%" }}></img>
    </div>
  );
}
