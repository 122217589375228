import React from "react";

export default function FooterToolBox({ pageIndex, pageCount }) {
  return (
    <div>
      <div style={{ position: "fixed", left: 5, bottom: 5 }}>
        Pagina {pageIndex + 1} di {pageCount}
      </div>
    </div>
  );
}
