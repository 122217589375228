import React from "react";
import { useSelector } from "react-redux";

import { selectPagina } from "../features/pagina/paginaSlice";
import {
  selectEntity,
  selectPageCount,
} from "../features/paragrafi/entitySlice";

import HtmlBox from "../components/HtmlBox";
import FrecceNavigazione from "../components/FrecceNavigazione";
import FooterToolBox from "../components/FooterToolBox";
import RigheTabella from "../components/RigheTabella";
import AddNew from "../components/AddNew";
import ImgSelector from "../components/ImgSelector";

import Editor from "../components/Editor";

const queryParams = new URLSearchParams(window.location.search);
const type = queryParams.get("type");

export default function PaginaVuotaTpl({ pageIndex }) {
  const { pageZoom } = useSelector(selectPagina);
  const Allentity = useSelector(selectEntity);
  const entity = Allentity[pageIndex];
  const pageCount = useSelector(selectPageCount);
  console.log(entity);
  return (
    <div className="container-prodotto" style={{ position: "relative" }}>
      <FrecceNavigazione />
      <page
        is="page"
        aria-size="A4"
        style={{ zoom: pageZoom, alignItems: "top" }}
      >
        {type != "blueprint" && (
          <div>
            <HtmlBox
              className="header"
              contenuto={Allentity[0].contenuti[0]}
              editor={false}
              chiave={-1}
              pageIndex={0}
              key={-1}
            />
          </div>
        )}

        <FooterToolBox pageIndex={pageIndex} pageCount={pageCount} />
        <div className="">
          <Editor
            pageIndex={pageIndex}
            contenuto={entity.contenuti[0]}
            chiave={0}
          ></Editor>
        </div>
        <img src="footer.png" style={{ width: "100%" }}></img>
      </page>
      <AddNew></AddNew>
    </div>
  );
}
