import React from "react";
import { useSelector } from "react-redux";

import { selectPagina } from "../features/pagina/paginaSlice";
import {
  selectEntity,
  selectPageCount,
} from "../features/paragrafi/entitySlice";

import HtmlBox from "../components/HtmlBox";
import FrecceNavigazione from "../components/FrecceNavigazione";
import FooterToolBox from "../components/FooterToolBox";
import RigheTabella from "../components/RigheTabella";
import AddNew from "../components/AddNew";
import ImgSelector from "../components/ImgSelector";

import "react-tiny-fab/dist/styles.css";

export default function RiepilogoEconomico({ pageIndex }) {
  const { pageZoom } = useSelector(selectPagina);
  const Allentity = useSelector(selectEntity);
  const entity = Allentity[pageIndex];
  const pageCount = useSelector(selectPageCount);

  return (
    <div className="container-prodotto" style={{ position: "relative" }}>
      <FrecceNavigazione />
      <page is="page" aria-size="A4" style={{ zoom: pageZoom }}>
        <div>
          <HtmlBox
            className="header"
            contenuto={Allentity[0].contenuti[0]}
            editor={false}
            chiave={-1}
            pageIndex={0}
            key={-1}
          />
        </div>
        <FooterToolBox pageIndex={pageIndex} pageCount={pageCount} />
        <div className="tabella-prodotto">
          <div style={{ width: "100%" }}>
            <table style={{ width: "100%" }} border={1}>
              <thead>
                <tr>
                  <th colSpan="4">
                    <HtmlBox
                      className="titolo"
                      contenuto={entity.contenuti[0]}
                      editor={false}
                      chiave={0}
                      pageIndex={pageIndex}
                    />
                  </th>
                </tr>
                <tr>
                  <td>
                    <HtmlBox
                      className="intestazione-colonna"
                      contenuto={entity.contenuti[1]}
                      editor={false}
                      chiave={1}
                      pageIndex={pageIndex}
                    />
                  </td>
                  <td>
                    <HtmlBox
                      className="intestazione-colonna"
                      contenuto={entity.contenuti[2]}
                      editor={false}
                      chiave={2}
                      pageIndex={pageIndex}
                    />
                  </td>
                  <td>
                    <HtmlBox
                      className="intestazione-colonna"
                      contenuto={entity.contenuti[3]}
                      editor={false}
                      chiave={3}
                      pageIndex={pageIndex}
                    />
                  </td>
                  <td>
                    <HtmlBox
                      className="intestazione-colonna"
                      contenuto={entity.contenuti[4]}
                      editor={false}
                      chiave={4}
                      pageIndex={pageIndex}
                    />
                  </td>
                </tr>

                <RigheTabella
                  className="intestazione-colonna"
                  contenuto={entity.contenuti[5]}
                  editor={false}
                  chiave={5}
                  pageIndex={pageIndex}
                ></RigheTabella>
                <tr>
                  <td
                    colSpan="3"
                    style={{ backgroundColor: "#000", color: "#fff" }}
                  >
                    <HtmlBox
                      className="intestazione-colonna"
                      contenuto={entity.contenuti[6]}
                      editor={false}
                      chiave={6}
                      pageIndex={pageIndex}
                    />
                  </td>
                  <td
                    colSpan="1"
                    style={{ backgroundColor: "#000", color: "#fff" }}
                  >
                    <HtmlBox
                      className="intestazione-colonna"
                      contenuto={entity.contenuti[7]}
                      editor={false}
                      chiave={7}
                      pageIndex={pageIndex}
                    />
                  </td>
                </tr>
              </thead>
            </table>
            <div>
              <HtmlBox
                className="intestazione-colonna"
                contenuto={entity.contenuti[8]}
                editor={false}
                chiave={8}
                pageIndex={pageIndex}
              />
            </div>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>
                    <HtmlBox
                      className="intestazione-colonna"
                      contenuto={entity.contenuti[9]}
                      editor={false}
                      chiave={9}
                      pageIndex={pageIndex}
                    />
                  </td>
                  <td>
                    {/*uguale alla somma della tabella*/}
                    <HtmlBox
                      className="intestazione-colonna"
                      contenuto={entity.contenuti[7]}
                      editor={false}
                      chiave={7}
                      pageIndex={pageIndex}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <img src="footer.png" style={{ width: "100%" }}></img>
      </page>
      <AddNew></AddNew>
    </div>
  );
}
