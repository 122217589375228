import React, { useState, useRef } from "react";

import { updateImmagine } from "../features/paragrafi/entitySlice";
import { useDispatch } from "react-redux";

export default function ImgSelector({ src, pageIndex, style, chiave = 4 }) {
  const [over, setOver] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  return (
    <>
      <input
        type="file"
        id="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          var file = e.target.files[0];
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.addEventListener(
            "load",
            () => {
              // convert image file to base64 string

              //console.log(reader.result);
              dispatch(
                updateImmagine({
                  pageIndex: pageIndex,
                  key: chiave,
                  value: reader.result,
                })
              );
            },
            false
          );
        }}
      />

      <div
        style={{ cursor: "pointer", position: "relative", ...style }}
        onMouseEnter={() => setOver(true)}
        onMouseLeave={() => setOver(false)}
        onClick={() => {
          inputRef.current.click();
        }}
      >
        {over && (
          <button
            style={{
              position: "absolute",
              fontSize: 30,
              backgroundColor: "green",
              color: "#fff",
              top: "calc(50% - 50px)",
              left: "calc(50% - 50px)",
              width: 50,
              heigth: 50,
              borderRadius: "50%",
              border: "none",
            }}
          >
            <i className="fa fa-image"></i>
          </button>
        )}
        <img src={src} style={{ width: "100%" }} />
      </div>
    </>
  );
}
