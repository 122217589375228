import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  updateValore,
  updateVisibilita,
  updateAttivo,
} from "../features/paragrafi/entitySlice";

import parseHTML from "../lib/parseHTML";

import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import "react-tiny-fab/dist/styles.css";

export default function Editor({ pageIndex, contenuto, className, chiave }) {
  const dispatch = useDispatch();
  //console.log(contenuto.valore !== undefined, contenuto);
  const valore = contenuto.valore;
  const html = parseHTML(valore, contenuto.parametri);

  const [editorHtml, setEditorHtml] = useState(valore);
  const [curIndex, setCurIndex] = useState(pageIndex);
  useEffect(() => {
    setEditorHtml(contenuto.valore);
    //console.log("effectIndex", pageIndex);
    editor.current.setOptions?.({
      buttonList: buttonList.complex,
      callBackSave: (contents) => {
        save();
      },
    });
    // return () => {
    //   editor.current.destroy?.();
    // };
  }, [pageIndex]);

  const editor = useRef();

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  const save = () => {
    {
      console.log(pageIndex);
      dispatch(
        updateValore({
          pageIndex: pageIndex,
          key: chiave,
          value: editor.current.getContents(true),
        })
      );
    }
  };

  return (
    <>
      <SunEditor
        getSunEditorInstance={getSunEditorInstance}
        height="600px"
        setOptions={{
          buttonList: buttonList.complex,
        }}
        setContents={editorHtml}
      />
    </>
  );
}
