export default function parseHTML(html, segnaposti, colore = true) {
  if (segnaposti === undefined) {
    return html;
  }
  // if (Array.isArray(html)) {
  //   return html;
  // }
  segnaposti.forEach((s) => {
    if (colore) {
      html = html.replace(
        `{{${s.segnaposto}}}`,
        `<span style="color:red">${s.valore}</span>`
      );
    } else {
      html = html.replace(`{{${s.segnaposto}}}`, `${s.valore}`);
    }
  });
  return html;
}
