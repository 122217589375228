import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectEntity,
  updateNomeComponente,
} from "../features/paragrafi/entitySlice";
import { selectPagina } from "../features/pagina/paginaSlice";
import {
  useUpdateEntityMutation,
  useUpdateProdottoMutation,
} from "../features/api/apiSlice";
import Modal from "./Modal";
const queryParams = new URLSearchParams(window.location.search);
const idOfferta = queryParams.get("pg");
const type = queryParams.get("type");
const ln = queryParams.get("ln");

export default function BtnSave() {
  const dispatch = useDispatch();
  const [updateEntity] = useUpdateEntityMutation();
  const [updateProdotto] = useUpdateProdottoMutation();
  const entity = useSelector(selectEntity);
  const [modalOpen, setModalOpen] = useState(false);
  const [codiceRevisione, setCodiceRevisione] = useState("");
  const { pageIndex } = useSelector(selectPagina);

  if (type === "blueprint") {
    return (
      <div style={{ backgroundClor: "rgb(238, 238, 238)", padding: "15px" }}>
        <button
          type="button"
          className="btn btn-success form-control"
          style={{ marginTop: 5, fontWeight: "bold" }}
          onClick={(e) => {
            updateProdotto({
              id: idOfferta,
              codiceRevisione: 0,
              entity,
              ln: ln | 1,
            }).then(() => {
              e.preventDefault();
              console.log(window.parent);
              window.parent.postMessage("ritornaAllaGriglia", "*");
            });
          }}
        >
          SALVA PRODOTTO
        </button>

        <button
          type="button"
          className="btn btn-warning form-control"
          style={{ marginTop: 5, fontWeight: "bold" }}
          onClick={() => setModalOpen(true)}
        >
          SALVA COME NUOVO
        </button>
        <Modal modalOpen={modalOpen} close={() => setModalOpen(false)}>
          <div>
            <h4>Nuovo prodotto</h4>
            <form>
              <div className="form-group">
                <label>Nome Componente</label>
                <input
                  className="form-control"
                  type="text"
                  value={entity[pageIndex].nomeComponente}
                  onChange={(e) => {
                    dispatch(
                      updateNomeComponente({
                        value: e.target.value,
                        pageIndex: pageIndex,
                      })
                    );
                  }}
                />
                <label>Tipologia Prodotto</label>
                <input
                  className="form-control"
                  type="text"
                  value={codiceRevisione}
                  onChange={(e) => setCodiceRevisione(e.target.value)}
                />

                <button
                  type="button"
                  className="btn btn-success "
                  style={{
                    margin: "25px auto",
                    width: 200,
                    fontWeight: "bold",
                    display: "block",
                  }}
                  onClick={(e) => {
                    setModalOpen(false);
                    updateProdotto({
                      id: idOfferta,
                      codiceRevisione,
                      entity,
                    }).then(() => {
                      e.preventDefault();
                      console.log(window.parent);
                      window.parent.postMessage("ritornaAllaGriglia", "*");
                    });
                  }}
                >
                  SALVA
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
  return (
    <div style={{ backgroundClor: "rgb(238, 238, 238)", padding: "15px" }}>
      <button
        type="button"
        className="btn btn-success form-control"
        style={{ marginTop: 5, fontWeight: "bold" }}
        onClick={() =>
          updateEntity({ id: idOfferta, codiceRevisione: 0, entity })
        }
      >
        SALVA
      </button>

      {/* <button
        type="button"
        className="btn btn-warning form-control"
        style={{ marginTop: 5, fontWeight: "bold" }}
        onClick={() => setModalOpen(true)}
      >
        SALVA REVISIONE
      </button> */}
      <Modal modalOpen={modalOpen} close={() => setModalOpen(false)}>
        <div>
          <h4>Salva come revisione</h4>
          <form>
            <div className="form-group">
              <label>Codice Revisione</label>
              <input
                className="form-control"
                type="text"
                value={codiceRevisione}
                onChange={(e) => setCodiceRevisione(e.target.value)}
              />
              <button
                type="button"
                className="btn btn-success "
                style={{
                  margin: "25px auto",
                  width: 200,
                  fontWeight: "bold",
                  display: "block",
                }}
                onClick={(e) => {
                  setModalOpen(false);
                  updateEntity({ id: idOfferta, codiceRevisione, entity }).then(
                    () => {
                      e.preventDefault();
                      console.log(window.parent);
                      window.parent.postMessage("ritornaAllaGriglia", "*");
                    }
                  );
                }}
              >
                SALVA
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
