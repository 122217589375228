import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectEntity,
  updateProprieta,
  updateVisibilita,
} from "../features/paragrafi/entitySlice";
import Toaster from "./Toaster";

export default function TextInput({ pageIndex, item, chiave }) {
  const dispatch = useDispatch();
  const entityAll = useSelector(selectEntity);
  const entity = entityAll[pageIndex];
  if (!entity.contenuti?.[chiave]?.attivo) {
    return null;
  }
  return (
    <div className="form-group">
      {item.parametri && entity.contenuti?.[chiave]?.attivo ? (
        <div
          style={{
            backgroundColor: entity.contenuti?.[chiave]?.attivo
              ? "#eee"
              : "#fff",
            padding: 15,
          }}
        >
          <legend>
            {item.label}
            {entity.contenuti?.[chiave]?.visibile ? (
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary float-right"
                style={{ margin: 5 }}
                onClick={() =>
                  dispatch(
                    updateVisibilita({
                      pageIndex: pageIndex,
                      key: chiave,
                      value: false,
                    })
                  )
                }
              >
                <i className="fa fa-trash"></i>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary float-right"
                style={{ margin: 5 }}
                onClick={() =>
                  dispatch(
                    updateVisibilita({
                      pageIndex: pageIndex,
                      key: chiave,
                      value: true,
                    })
                  )
                }
              >
                <i className="fa fa-undo"></i>
              </button>
            )}
          </legend>
          <label>Segnaposti:</label>
          {switchComponente(
            item.parametri,
            entity,
            chiave,
            dispatch,
            updateProprieta,
            pageIndex
          )}
        </div>
      ) : (
        <Toaster
          titolo="il paragrafo selezionato non ha segnaposto,"
          testo="( se vuoi modificare il testo puoi usare l'editor in corrispondenza
          del paragrafostesso)"
        />
      )}
    </div>
  );
}

function switchComponente(
  parametri,
  entity,
  chiave,
  dispatch,
  updateProprieta,
  pageIndex
) {
  return parametri.map((parametro, kk) => {
    switch (parametro.componente) {
      case "input":
        return (
          <div className="form-group" key={kk}>
            <label>
              {" "}
              {parametro.segnaposto} {parametro.readonly}:
            </label>
            <input
              className="form-control"
              type="text"
              value={entity.contenuti?.[chiave]?.parametri?.[kk]?.valore}
              readOnly={parametro.readonly}
              onChange={(e) => {
                console.log(parametro);
                dispatch(
                  updateProprieta({
                    key: chiave,
                    parametro: kk,
                    value: e.target.value,
                    pageIndex: pageIndex,
                  })
                );
              }}
            ></input>
          </div>
        );

        break;
      case "select":
        return (
          <div className="form-group" key={kk}>
            <label> {parametro.segnaposto} :</label>
            <select
              className="form-control"
              value={entity.contenuti?.[chiave]?.parametri?.[kk]?.valore}
              onChange={(e) =>
                dispatch(
                  updateProprieta({
                    key: chiave,
                    parametro: kk,
                    value: e.target.value,
                    pageIndex: pageIndex,
                  })
                )
              }
            >
              {parametro.valore.map((v, ok) => (
                <option value={v} key={ok}>
                  {v}
                </option>
              ))}
            </select>
          </div>
        );
        break;
      case "text":
        return (
          <div className="form-group" key={kk}>
            <label> {parametro.segnaposto} :</label>
            <textarea
              className="form-control"
              value={entity.contenuti?.[chiave]?.parametri?.[kk]?.valore}
              onChange={(e) =>
                dispatch(
                  updateProprieta({
                    key: chiave,
                    parametro: kk,
                    value: e.target.value,
                    pageIndex: pageIndex,
                  })
                )
              }
            ></textarea>
          </div>
        );

        break;
      default:
        return "";
        break;
    }
  });
}
