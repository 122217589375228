import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { store } from "../../app/store";
import { initEntity } from "../../features/paragrafi/entitySlice";
import { initBp } from "../../features/paragrafi/blueprintSlice";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://tecno-offerte.kne.it/api/offerte/",
  }),
  endpoints: (builder) => ({
    getEntity: builder.query({
      query: ({ id, storico }) => `offerta/${id}/${storico}/`,
      transformResponse: (response, meta, arg) => {
        //console.log(response);
        store.dispatch(initEntity(response));
        //return response.data;
      },
    }),
    getProdotto: builder.query({
      query: ({ id, ln }) => `prodotto/${id}/${ln}/`,
      transformResponse: (response, meta, arg) => {
        console.log(response);
        store.dispatch(initEntity(response));
        //return response.data;
      },
    }),
    updateEntity: builder.mutation({
      // note: an optional `queryFn` may be used in place of `query`
      query: ({ id, codiceRevisione, ...entity }) => ({
        url: `offerta/${id}/${codiceRevisione}/`,
        method: "POST",
        body: entity.entity,
      }),
    }),
    updateProdotto: builder.mutation({
      // note: an optional `queryFn` may be used in place of `query`
      query: ({ id, codiceRevisione, ln, ...entity }) => ({
        url: `prodotto/${id}/${codiceRevisione}/${ln}/`,
        method: "POST",
        body: entity.entity,
      }),
    }),
    getBp: builder.query({
      query: ({ entity, ln }) => ({
        url: `bp/${ln | 1}/`,
        method: "POST",
        body: entity.map((e) => e.codiceNavision),
      }),
      transformResponse: (response, meta, arg) => {
        //console.log(response);
        store.dispatch(initBp(response));
      },
    }),
    getLnOfferta: builder.query({
      query: ({ id }) => `lnOfferta/${id}/`,
    }),
    getComponenti: builder.query({
      query: () => `componente/`,
    }),
    getImmagini: builder.query({
      query: () => `immagini/`,
    }),
  }),
});

export const {
  useGetPilastroQuery,
  useGetBpQuery,
  useGetEntityQuery,
  useGetLnOffertaQuery,
  useGetProdottoQuery,
  useGetComponentiQuery,
  useGetImmaginiQuery,
  useUpdateEntityMutation,
  useUpdateProdottoMutation,
} = apiSlice;
