import { createSlice } from "@reduxjs/toolkit";

//console.log(JSON.stringify(pilastroBlueprintInitialState));

// qui a tendere ci andrà un thunk che caricherà il blueprint selezionato (pilastro, trave etc)
const initialState = [];

export const blueprintSlice = createSlice({
  name: "blueprint",
  initialState: initialState,
  reducers: {
    initBp: (state, action) => {
      //console.log(action.payload);
      return action.payload;
    },
  },
});

export const { initBp } = blueprintSlice.actions;

export const selectBlueprint = (state) => state.blueprint;

export default blueprintSlice.reducer;
