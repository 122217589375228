import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGetComponentiQuery, useGetBpQuery } from "../features/api/apiSlice";
import { selectEntity, insertPage } from "../features/paragrafi/entitySlice";
import { selectPagina } from "../features/pagina/paginaSlice";
export default function TemplateSelector({ selected }) {
  const dispatch = useDispatch();

  const { pageIndex } = useSelector(selectPagina);

  const [pageAt, setPageAt] = useState(pageIndex + 1);
  const [selectedComponent, setSelectedComponent] = useState();

  const {
    data: prodotti,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetComponentiQuery();

  if (isLoading) {
    return <p>loading</p>;
  }
  return (
    <div>
      <h4>{selected}</h4>
      <label>Aggiungi pagina:</label>
      <select
        required
        className="form-control"
        onChange={(e) => setSelectedComponent(prodotti[e.target.value])}
      >
        {prodotti.map((p, k) => (
          <option key={k} value={k}>
            {p.title}
          </option>
        ))}
      </select>
      <label>Inserisci alla pagina:</label>
      <input
        type="number"
        className="form-control"
        value={pageAt}
        onChange={(e) => setPageAt(parseInt(e.target.value))}
      ></input>
      <button
        style={{
          position: "absolute",
          bottom: 10,
          right: 10,
          width: "100px",
        }}
        className="btn btn-success"
        onClick={
          () => {
            dispatch(
              insertPage({
                pageAt: pageAt,
                bp: JSON.parse(
                  selectedComponent ? selectedComponent.json : prodotti[0].json
                ),
              })
            );
          }
          // () => {
          //   console.log(JSON.parse(selectedComponent.json));
          // }
        }
      >
        OK
      </button>
    </div>
  );
}
