import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  updateValore,
  updateVisibilita,
  updateAttivo,
} from "../features/paragrafi/entitySlice";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.bubble.css";

import parseHTML from "../lib/parseHTML";

export default function HtmlBox({ pageIndex, contenuto, className, chiave }) {
  const dispatch = useDispatch();
  //console.log(contenuto.valore !== undefined, contenuto);
  const valore = contenuto.valore;
  const html = parseHTML(valore, contenuto.parametri);
  const [editMode, setEditMode] = useState(false);
  const [editorHtml, setEditorHtml] = useState(valore);
  const [editButtonVisible, setEditButtonVisible] = useState(false);
  const [over, setOver] = useState(false);
  useEffect(() => setEditorHtml(contenuto.valore), [contenuto]);

  //console.log("contenuto", contenuto);
  if (!contenuto.visibile) return null;

  // configurazione editor
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  return !editMode ? (
    <div
      style={{
        position: "relative",
        backgroundColor: over ? "#f9f9f9" : "#fff",
        border: over ? "1px solid #ccc" : "1px solid #fff",
        padding: 5,
        width: "100%",
      }}
      onMouseEnter={() => {
        setEditButtonVisible(true);
        setOver(true);
      }}
      onMouseLeave={() => {
        setOver(false);
        setEditButtonVisible(false);
      }}
      onClick={() =>
        dispatch(
          updateAttivo({ pageIndex: pageIndex, key: chiave, value: true })
        )
      }
    >
      {editButtonVisible ? (
        <>
          <button
            type="button"
            className="btn  btn-sm btn-secondary"
            style={{
              position: "absolute",
              top: "-15px",
              right: "-15px",
              borderRadius: "50%",
            }}
            onClick={() => setEditMode(!editMode)}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            type="button"
            className="btn  btn-sm btn-danger"
            style={{
              position: "absolute",
              top: "-15px",
              left: "-15px",
              borderRadius: "50%",
            }}
            onClick={() => {
              if (window.confirm("eliminare elemento?")) {
                dispatch(
                  updateVisibilita({
                    pageIndex: pageIndex,
                    key: chiave,
                    value: false,
                  })
                );
              }
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </>
      ) : null}
      <div
        className={`${className} ql-editor`}
        style={{
          padding: 0,
          border: "none",
        }}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </div>
  ) : (
    <div style={{ position: "relative" }}>
      <ReactQuill
        theme="bubble"
        modules={modules}
        formats={formats}
        value={editorHtml}
        onChange={setEditorHtml}
      />
      <button
        type="button"
        className="btn btn-success btn-sm"
        onClick={() => {
          dispatch(
            updateValore({
              pageIndex: pageIndex,
              key: chiave,
              value: editorHtml,
            })
          );
          setEditMode(!editMode);
        }}
        style={{
          position: "absolute",
          top: "-25px",
          right: "-25px",
          borderRadius: "50%",
        }}
      >
        <i className="fa fa-check"></i>
      </button>
    </div>
  );
}
