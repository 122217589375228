import React, { useState, useEffect } from "react";
import { Fab, Action } from "react-tiny-fab";

import Modal from "./Modal";
import TemplateSelector from "./TemplateSelector";
//import listaProdotti from "../features/listaProdotti";

const queryParams = new URLSearchParams(window.location.search);
const type = queryParams.get("type");

export default function AddNew() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState(false);

  if (type === "blueprint") {
    return <></>;
  }

  const modalContents = (
    <TemplateSelector selected={selected}></TemplateSelector>
  );

  function handleClick(selectedItem) {
    setSelected(selectedItem);
    setModalOpen(true);
  }

  return (
    <>
      <Modal modalOpen={modalOpen} close={() => setModalOpen(false)}>
        {modalContents}
      </Modal>
      <Fab icon={<i className="fa fa-plus" />}>
        // The Action components are the "buttons" that appear when the Fab is
        open. You can use the out-of-the-box Action // component or you can use
        a custom component of any type and style it any way that you'd like. The
        "text" prop // is the popup label that appears when the Action component
        is hovered.
        <Action
          text="Sol speciali"
          style={{ backgroundColor: "#B8CCE4" }}
          onClick={() => handleClick("solSpeciali")}
        >
          Ss
        </Action>
        <Action
          text="Opzionali"
          style={{ backgroundColor: "#92D050" }}
          onClick={() => handleClick("opzionali")}
        >
          Op
        </Action>
        <Action
          text="Posa"
          style={{ backgroundColor: "#FABF8F" }}
          onClick={() => handleClick("posa")}
        >
          Po
        </Action>
        <Action
          text="Solaio"
          style={{ backgroundColor: "#B1A0C7" }}
          onClick={() => handleClick("solaio")}
        >
          So
        </Action>
        <Action
          text="Trave"
          style={{ backgroundColor: "#DA9694" }}
          onClick={() => handleClick("trave")}
        >
          Tr
        </Action>
        <Action
          text="Pilastri"
          style={{ backgroundColor: "#92CDDC" }}
          onClick={() => handleClick("pilastro")}
        >
          Pi
        </Action>
      </Fab>
    </>
  );
}
