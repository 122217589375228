import React from "react";

import PdfHtmlBox from "../components/PdfHtmlBox";

import "react-tiny-fab/dist/styles.css";

export default function PdfPaginaProdottoTpl({ item }) {
  return (
    <div is="page" aria-size="A4">
      <div>
        <PdfHtmlBox
          className="titolo"
          contenuto={item.contenuti[0]}
          editor={false}
          chiave={0}
        />
      </div>
      <img src="footer.png" style={{ width: "100%" }}></img>
    </div>
  );
}
