import React from "react";

import parseHTML from "../lib/parseHTML";

export default function PdfHtmlBox({ contenuto, className, chiave }) {
  const html = parseHTML(contenuto.valore, contenuto.parametri, false);

  if (!contenuto.visibile) return null;
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}
