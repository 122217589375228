import React from "react";
import { useSelector } from "react-redux";

import { selectPagina } from "../features/pagina/paginaSlice";
import {
  selectEntity,
  selectPageCount,
} from "../features/paragrafi/entitySlice";

import HtmlBox from "../components/HtmlBox";
import FrecceNavigazione from "../components/FrecceNavigazione";
import FooterToolBox from "../components/FooterToolBox";
import ImgSelector from "../components/ImgSelector";
import AddNew from "../components/AddNew";

import tImg from "../copertina.png.base64";

import "react-tiny-fab/dist/styles.css";
export default function Testata() {
  const { pageIndex, pageZoom } = useSelector(selectPagina);
  const pageCount = useSelector(selectPageCount);
  const Allentity = useSelector(selectEntity);
  const entity = Allentity[pageIndex];

  return (
    <div className="container-prodotto" style={{ position: "relative" }}>
      <FrecceNavigazione />
      <page is="page" aria-size="A4" style={{ zoom: pageZoom }}>
        <FooterToolBox pageIndex={pageIndex} pageCount={pageCount} />
        <div className="">
          <div className="">
            {entity.contenuti.map((e, k) => {
              let style = {};
              switch (k) {
                case 0:
                  return (
                    <div key={k}>
                      <div
                        style={{
                          position: "absolute",
                          left: "50px",
                          top: "150px",

                          float: "right",
                          zIndex: 10,
                          background: "none",
                        }}
                      >
                        <HtmlBox
                          className="titolo"
                          contenuto={e}
                          editor={true}
                          chiave={k}
                          pageIndex={pageIndex}
                          key={k}
                          style={style}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  );
                case 1:
                  return (
                    <ImgSelector
                      className="immagine"
                      chiave={1}
                      src={e.valore}
                      pageIndex={pageIndex}
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        zIndex: 0,
                      }}
                    />
                  );
                case 2:
                  return (
                    <ImgSelector
                      className="immagine"
                      chiave={2}
                      src={e.valore}
                      pageIndex={pageIndex}
                      style={{
                        position: "absolute",
                        top: 555,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        zIndex: 0,
                        background: "#fff",
                      }}
                    />
                  );
                case 4:
                  return (
                    <div
                      style={{
                        position: "absolute",
                        top: "400px",
                        background: "transparent",
                        width: "80%",
                        borderBottom: "2px solid #000",
                      }}
                    >
                      <HtmlBox
                        className="titolo"
                        contenuto={e}
                        editor={false}
                        chiave={k}
                        pageIndex={pageIndex}
                        key={k}
                        style={{ background: "none" }}
                      />
                    </div>
                  );
                case 5:
                  return (
                    <div
                      style={{
                        position: "absolute",
                        top: "150px",
                        right: "10px",
                        // border: "1px solid #000",
                        background: "transparent",
                        width: "30%",
                        zIndex: "1000",
                        fontWeight: 800,
                      }}
                    >
                      <HtmlBox
                        className="titolo"
                        contenuto={e}
                        editor={false}
                        chiave={k}
                        pageIndex={pageIndex}
                        key={k}
                        style={{ background: "none" }}
                      />
                    </div>
                  );
                default:
                  return (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "100px",
                        background: "transparent",
                        width: "500px",
                      }}
                    >
                      <HtmlBox
                        className="titolo"
                        contenuto={e}
                        editor={false}
                        chiave={k}
                        pageIndex={pageIndex}
                        key={k}
                        style={{ background: "none" }}
                      />
                    </div>
                  );
              }
            })}
          </div>
        </div>
        <img src="footer.png" style={{ width: "100%" }}></img>
      </page>
    </div>
  );
}
