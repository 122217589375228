import React from "react";

import PdfHtmlBox from "../components/PdfHtmlBox";
import RigheTabella from "../components/RigheTabella";
import "react-tiny-fab/dist/styles.css";

export default function PdfRiepilogoEconomico({ item }) {
  return (
    <div is="page" aria-size="A4">
      <div className="">
        <div style={{ width: "100%" }}>
          <div className="">
            <div style={{ width: "100%" }}>
              <table border="0" style={{ width: "100%", marginBottom: 35 }}>
                <tr>
                  <td style={{ borderBottom: "2px solid #000" }}>
                    <PdfHtmlBox
                      className="intestazione-colonna"
                      contenuto={item.contenuti[17]}
                      editor={false}
                      chiave={17}
                    />
                  </td>
                  <td style={{ borderBottom: "2px solid #000" }}>
                    <PdfHtmlBox
                      className="intestazione-colonna"
                      contenuto={item.contenuti[18]}
                      editor={false}
                      chiave={18}
                    />
                  </td>
                  <td style={{ borderBottom: "2px solid #000" }}>
                    <PdfHtmlBox
                      className="intestazione-colonna"
                      contenuto={item.contenuti[19]}
                      editor={false}
                      chiave={19}
                    />
                  </td>
                  <td style={{ borderBottom: "2px solid #000" }}>
                    <PdfHtmlBox
                      className="intestazione-colonna"
                      contenuto={item.contenuti[20]}
                      editor={false}
                      chiave={20}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <PdfHtmlBox
                      className="intestazione-colonna"
                      contenuto={item.contenuti[21]}
                      editor={false}
                      chiave={21}
                    />
                  </td>
                  <td>
                    <PdfHtmlBox
                      className="intestazione-colonna"
                      contenuto={item.contenuti[22]}
                      editor={false}
                      chiave={22}
                    />
                  </td>
                  <td>
                    <PdfHtmlBox
                      className="intestazione-colonna"
                      contenuto={item.contenuti[23]}
                      editor={false}
                      chiave={23}
                    />
                  </td>
                  <td>
                    <PdfHtmlBox
                      className="intestazione-colonna"
                      contenuto={item.contenuti[24]}
                      editor={false}
                      chiave={24}
                    />
                  </td>
                </tr>
              </table>

              <div style={{ marginBottom: 35 }}>
                <PdfHtmlBox
                  className="intestazione-colonna"
                  contenuto={item.contenuti[12]}
                  editor={false}
                  chiave={12}
                />
              </div>
              <table
                style={{ width: "100%", fontSize: 14, marginBottom: 35 }}
                border={1}
              >
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#eee",
                      fontWeight: "bold",
                      fontSize: "0.9em",
                      textAlign: "center",
                      padding: 15,
                    }}
                  >
                    <td style={{ backgroundColor: "#eee", padding: 10 }}>
                      <PdfHtmlBox
                        className="intestazione-colonna"
                        contenuto={item.contenuti[1]}
                        editor={false}
                        chiave={1}
                      />
                    </td>
                    <td style={{ backgroundColor: "#eee" }}>
                      <PdfHtmlBox
                        className="intestazione-colonna"
                        contenuto={item.contenuti[2]}
                        editor={false}
                        chiave={2}
                      />
                    </td>
                    <td style={{ backgroundColor: "#eee", width: 80 }}>
                      <PdfHtmlBox
                        className="intestazione-colonna"
                        contenuto={item.contenuti[3]}
                        editor={false}
                        chiave={3}
                      />
                    </td>
                    <td style={{ backgroundColor: "#eee", textAlign: "right" }}>
                      <PdfHtmlBox
                        className="intestazione-colonna"
                        contenuto={item.contenuti[4]}
                        editor={false}
                        chiave={4}
                      />
                    </td>
                    <td style={{ backgroundColor: "#eee", textAlign: "right" }}>
                      <PdfHtmlBox
                        className="intestazione-colonna"
                        contenuto={item.contenuti[5]}
                        editor={false}
                        chiave={5}
                      />
                    </td>
                    <td
                      style={{
                        backgroundColor: "#eee",
                        textAlign: "right",
                        width: 150,
                      }}
                    >
                      <PdfHtmlBox
                        className="intestazione-colonna"
                        contenuto={item.contenuti[6]}
                        editor={false}
                        chiave={6}
                      />
                    </td>
                  </tr>

                  <RigheTabella
                    className="intestazione-colonna"
                    contenuto={item.contenuti[7]}
                    editor={false}
                    chiave={7}
                  ></RigheTabella>

                  <tr>
                    <td
                      colSpan="5"
                      style={{
                        backgroundColor: "#eee",
                        fontWeight: "bold",
                        fontSize: 14,
                      }}
                    >
                      <PdfHtmlBox
                        className="intestazione-colonna"
                        contenuto={item.contenuti[8]}
                        editor={false}
                        chiave={8}
                      />
                    </td>
                    <td
                      colSpan="1"
                      style={{
                        backgroundColor: "#eee",
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      <PdfHtmlBox
                        className="intestazione-colonna"
                        contenuto={item.contenuti[9]}
                        editor={false}
                        chiave={9}
                      />
                    </td>
                  </tr>
                </thead>
              </table>
              <div>
                <PdfHtmlBox
                  className="intestazione-colonna"
                  contenuto={item.contenuti[10]}
                  editor={false}
                  chiave={10}
                />
              </div>
              <div>
                <PdfHtmlBox
                  className="intestazione-colonna"
                  contenuto={item.contenuti[11]}
                  editor={false}
                  chiave={11}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="footer.png" style={{ width: "100%" }}></img>
    </div>
  );
}
