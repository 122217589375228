import React from "react";
import { useSelector } from "react-redux";

import { selectPagina } from "../features/pagina/paginaSlice";
import {
  selectEntity,
  selectPageCount,
} from "../features/paragrafi/entitySlice";

import HtmlBox from "../components/HtmlBox";
import FrecceNavigazione from "../components/FrecceNavigazione";
import FooterToolBox from "../components/FooterToolBox";
import AddNew from "../components/AddNew";
import ImgSelector from "../components/ImgSelector";

import "react-tiny-fab/dist/styles.css";

const queryParams = new URLSearchParams(window.location.search);

const type = queryParams.get("type");

export default function PaginaProdottoTpl({ pageIndex }) {
  const { pageZoom } = useSelector(selectPagina);
  const Allentity = useSelector(selectEntity);
  const entity = Allentity[pageIndex];
  const pageCount = useSelector(selectPageCount);

  return (
    <div className="container-prodotto" style={{ position: "relative" }}>
      <FrecceNavigazione />
      <page is="page" aria-size="A4" style={{ zoom: pageZoom }}>
        {type != "blueprint" && (
          <div>
            <HtmlBox
              className="header"
              contenuto={Allentity[0].contenuti[0]}
              editor={false}
              chiave={-1}
              pageIndex={0}
              key={-1}
            />
          </div>
        )}
        <FooterToolBox pageIndex={pageIndex} pageCount={pageCount} />
        <div className="tabella-prodotto">
          <div className="colonna-sx">
            {
              <HtmlBox
                className="titolo"
                contenuto={entity.contenuti[0]}
                editor={false}
                chiave={0}
                pageIndex={pageIndex}
              />
            }
            <ImgSelector
              className="immagine"
              src={entity.contenuti[1].valore}
              pageIndex={pageIndex}
              chiave={1}
            />
          </div>
          <div className="colonna-dx">
            <HtmlBox
              className="titolo"
              contenuto={entity.contenuti[2]}
              editor={false}
              chiave={2}
              pageIndex={pageIndex}
            />
            <HtmlBox
              className="titolo"
              contenuto={entity.contenuti[3]}
              editor={false}
              chiave={3}
              pageIndex={pageIndex}
            />
            <HtmlBox
              className="titolo"
              contenuto={entity.contenuti[4]}
              editor={false}
              chiave={4}
              pageIndex={pageIndex}
            />
            <HtmlBox
              className="titolo"
              contenuto={entity.contenuti[5]}
              editor={false}
              chiave={5}
              pageIndex={pageIndex}
            />
            <HtmlBox
              className="titolo"
              contenuto={entity.contenuti[6]}
              editor={false}
              chiave={6}
              pageIndex={pageIndex}
            />

            <HtmlBox
              className="titolo"
              contenuto={entity.contenuti[7]}
              editor={false}
              chiave={7}
              pageIndex={pageIndex}
            />
          </div>
          {/*
            <HtmlBox
              className="descrizione-breve"
              contenuto={entity.contenuti[1]}
              chiave={1}
              pageIndex={pageIndex}
            />
            <HtmlBox
              className="descrizione-riepilogo-Economico"
              contenuto={entity.contenuti[2]}
              chiave={2}
              pageIndex={pageIndex}
            />
            <HtmlBox
              className="posizione"
              contenuto={entity.contenuti[3]}
              chiave={3}
              pageIndex={pageIndex}
            />
            
 
          <div className="colonna-dx">
            <HtmlBox
              className="descrizione"
              contenuto={entity.contenuti[5]}
              chiave={5}
              pageIndex={pageIndex}
            />
            <HtmlBox
              className="tipologia"
              contenuto={entity.contenuti[6]}
              chiave={6}
              pageIndex={pageIndex}
            />
            <HtmlBox
              className="dimensioni dettagli"
              contenuto={entity.contenuti[7]}
              chiave={7}
              pageIndex={pageIndex}
            />
            <HtmlBox
              className="resistenza-al-fuoco dettagli"
              contenuto={entity.contenuti[8]}
              chiave={8}
              pageIndex={pageIndex}
            />
            <HtmlBox
              className="trattamenti-superficiali dettagli"
              contenuto={entity.contenuti[9]}
              chiave={9}
              pageIndex={pageIndex}
            /> */}
        </div>
        <img src="footer.png" style={{ width: "100%" }}></img>
      </page>
      <AddNew></AddNew>
    </div>
  );
}
