import React from "react";

import PdfHtmlBox from "../components/PdfHtmlBox";

import "react-tiny-fab/dist/styles.css";

export default function PdfCondizioniEconomicheTpf({ item }) {
  return (
    <div className="" style={{ position: "relative" }}>
      <div className="">
        <table>
          <tr>
            <td>
              {" "}
              <PdfHtmlBox
                className="intestazione-colonna"
                contenuto={item.contenuti[0]}
                editor={false}
                chiave={0}
              />
            </td>
            <td>
              {" "}
              <PdfHtmlBox
                className="intestazione-colonna"
                contenuto={item.contenuti[1]}
                editor={false}
                chiave={1}
              />
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td>
              {" "}
              <PdfHtmlBox
                className="intestazione-colonna"
                contenuto={item.contenuti[2]}
                editor={false}
                chiave={2}
              />
            </td>
            <td>
              {" "}
              <PdfHtmlBox
                className="intestazione-colonna"
                contenuto={item.contenuti[3]}
                editor={false}
                chiave={3}
              />
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td>
              {" "}
              <PdfHtmlBox
                className="intestazione-colonna"
                contenuto={item.contenuti[4]}
                editor={false}
                chiave={4}
              />
            </td>
            <td>
              {" "}
              <PdfHtmlBox
                className="intestazione-colonna"
                contenuto={item.contenuti[5]}
                editor={false}
                chiave={5}
              />
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td>
              {" "}
              <PdfHtmlBox
                className="intestazione-colonna"
                contenuto={item.contenuti[6]}
                editor={false}
                chiave={6}
              />
            </td>
            <td>
              {" "}
              <PdfHtmlBox
                className="intestazione-colonna"
                contenuto={item.contenuti[7]}
                editor={false}
                chiave={7}
              />
            </td>
          </tr>
        </table>
        <p>
          <PdfHtmlBox
            className="intestazione-colonna"
            contenuto={item.contenuti[8]}
            editor={false}
            chiave={8}
          />
        </p>
        <table cellpadding="25" style={{ width: "100%" }}>
          <tr>
            <td>
              <PdfHtmlBox
                className="intestazione-colonna"
                contenuto={item.contenuti[9]}
                editor={false}
                chiave={9}
              />
              <br />
              <hr />
            </td>
            <td>
              <PdfHtmlBox
                className="intestazione-colonna"
                contenuto={item.contenuti[10]}
                editor={false}
                chiave={10}
              />
              <br />
              <hr />
            </td>
            <td>
              <PdfHtmlBox
                className="intestazione-colonna"
                contenuto={item.contenuti[11]}
                editor={false}
                chiave={11}
              />
              <br />
              <hr />
            </td>
          </tr>
        </table>
        <p>
          <PdfHtmlBox
            className="intestazione-colonna"
            contenuto={item.contenuti[12]}
            editor={false}
            chiave={12}
          />
        </p>
      </div>
      <img src="footer.png" style={{ width: "100%" }}></img>
    </div>
  );
}
