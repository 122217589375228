import React from "react";
import { useSelector } from "react-redux";

import { selectPagina } from "../features/pagina/paginaSlice";
import {
  selectEntity,
  selectPageCount,
} from "../features/paragrafi/entitySlice";

import HtmlBox from "../components/HtmlBox";
import FrecceNavigazione from "../components/FrecceNavigazione";
import FooterToolBox from "../components/FooterToolBox";
import RigheTabella from "../components/RigheTabella";
import AddNew from "../components/AddNew";
import ImgSelector from "../components/ImgSelector";

import "react-tiny-fab/dist/styles.css";

export default function CondizioniEconomicheTpl({ pageIndex }) {
  const { pageZoom } = useSelector(selectPagina);
  const Allentity = useSelector(selectEntity);
  const entity = Allentity[pageIndex];
  const pageCount = useSelector(selectPageCount);
  console.log(entity);
  return (
    <div className="container-prodotto" style={{ position: "relative" }}>
      <FrecceNavigazione />
      <page
        is="page"
        aria-size="A4"
        style={{ zoom: pageZoom, alignItems: "top" }}
      >
        <div>
          <HtmlBox
            className="header"
            contenuto={Allentity[0].contenuti[0]}
            editor={false}
            chiave={-1}
            pageIndex={0}
            key={-1}
          />
        </div>

        <FooterToolBox pageIndex={pageIndex} pageCount={pageCount} />
        <div className="">
          <table>
            <tr>
              <td>
                {" "}
                <HtmlBox
                  className="intestazione-colonna"
                  contenuto={entity.contenuti[0]}
                  editor={false}
                  chiave={0}
                  pageIndex={pageIndex}
                />
              </td>
              <td>
                {" "}
                <HtmlBox
                  className="intestazione-colonna"
                  contenuto={entity.contenuti[1]}
                  editor={false}
                  chiave={1}
                  pageIndex={pageIndex}
                />
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                {" "}
                <HtmlBox
                  className="intestazione-colonna"
                  contenuto={entity.contenuti[2]}
                  editor={false}
                  chiave={2}
                  pageIndex={pageIndex}
                />
              </td>
              <td>
                {" "}
                <HtmlBox
                  className="intestazione-colonna"
                  contenuto={entity.contenuti[3]}
                  editor={false}
                  chiave={3}
                  pageIndex={pageIndex}
                />
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                {" "}
                <HtmlBox
                  className="intestazione-colonna"
                  contenuto={entity.contenuti[4]}
                  editor={false}
                  chiave={4}
                  pageIndex={pageIndex}
                />
              </td>
              <td>
                {" "}
                <HtmlBox
                  className="intestazione-colonna"
                  contenuto={entity.contenuti[5]}
                  editor={false}
                  chiave={5}
                  pageIndex={pageIndex}
                />
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                {" "}
                <HtmlBox
                  className="intestazione-colonna"
                  contenuto={entity.contenuti[6]}
                  editor={false}
                  chiave={6}
                  pageIndex={pageIndex}
                />
              </td>
              <td>
                {" "}
                <HtmlBox
                  className="intestazione-colonna"
                  contenuto={entity.contenuti[7]}
                  editor={false}
                  chiave={7}
                  pageIndex={pageIndex}
                />
              </td>
            </tr>
          </table>
          <p>
            <HtmlBox
              className="intestazione-colonna"
              contenuto={entity.contenuti[8]}
              editor={false}
              chiave={8}
              pageIndex={pageIndex}
            />
          </p>
          <table cellpadding="25" style={{ width: "100%" }}>
            <tr>
              <td>
                <HtmlBox
                  className="intestazione-colonna"
                  contenuto={entity.contenuti[9]}
                  editor={false}
                  chiave={9}
                  pageIndex={pageIndex}
                />
                <br />
                <hr />
              </td>
              <td>
                <HtmlBox
                  className="intestazione-colonna"
                  contenuto={entity.contenuti[10]}
                  editor={false}
                  chiave={10}
                  pageIndex={pageIndex}
                />
                <br />
                <hr />
              </td>
              <td>
                <HtmlBox
                  className="intestazione-colonna"
                  contenuto={entity.contenuti[11]}
                  editor={false}
                  chiave={11}
                  pageIndex={pageIndex}
                />
                <br />
                <hr />
              </td>
            </tr>
          </table>
          <p>
            <HtmlBox
              className="intestazione-colonna"
              contenuto={entity.contenuti[12]}
              editor={false}
              chiave={12}
              pageIndex={pageIndex}
            />
          </p>
        </div>
        <img src="footer.png" style={{ width: "100%" }}></img>
      </page>
      <AddNew></AddNew>
    </div>
  );
}
