import React from "react";
import Fadein from "react-fade-in";

export default function Toaster({ titolo, testo }) {
  return (
    <Fadein>
      <div className="form-group container">
        <div className="toaster">
          <i className="fa fa-info-circle"></i> <span>{titolo}</span>
          <br />
          <small>{testo}</small>
        </div>
      </div>
    </Fadein>
  );
}
