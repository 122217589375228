import React from "react";
import ReactDom from "react-dom";

export default function Modal({ modalOpen, children, close }) {
  const MODAL = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: 25,
    zIndex: 10000,
    width: "50vw",
    height: "60vh",
  };
  const OVERLAY = {
    position: "fixed",
    left: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,.7)",
    zIndex: 10000,
  };

  const CLOSE = {
    position: "absolute",
    top: 10,
    right: 15,
    fontSize: 20,
    cursor: "pointer",
  };
  if (!modalOpen) return null;

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY}></div>
      <div style={MODAL}>
        <i className="fa fa-times" style={CLOSE} onClick={close}></i>
        {children}
      </div>
      ;
    </>,
    document.getElementById("portal")
  );
}
