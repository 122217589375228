import { createSlice } from "@reduxjs/toolkit";

const initialState = { pageIndex: 0, pageZoom: 1 };

export const paginaSlice = createSlice({
  name: "pagina",
  initialState: initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state) => {
      state.pageIndex = state.pageIndex + 1;
    },
    decrement: (state) => {
      state.pageIndex = state.pageIndex - 1;
    },
    updateZoom: (state, action) => {
      state.pageZoom = parseFloat(action.payload);
    },
  },
});

export const { increment, decrement, updateZoom } = paginaSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPagina = (state) => state.pagina;

export default paginaSlice.reducer;
