import React from "react";
import { useSelector } from "react-redux";
import { selectBlueprint } from "../features/paragrafi/blueprintSlice";
import { selectEntity } from "../features/paragrafi/entitySlice";
import { selectPagina } from "../features/pagina/paginaSlice";
import { useGetBpQuery, useGetLnOffertaQuery } from "../features/api/apiSlice";

import BtnSave from "./BtnSave";
import TextInput from "./TextInput";
import Toaster from "./Toaster";

const queryParams = new URLSearchParams(window.location.search);
const idOfferta = queryParams.get("pg");
const type = queryParams.get("type");
const storico = queryParams.get("storico");
let ln = queryParams.get("ln");

export default function FormSegnaposto() {
  const blueprintState = useSelector(selectBlueprint);
  const { pageIndex } = useSelector(selectPagina);
  const entity = useSelector(selectEntity);
  const {
    isLoading: lnLoading,
    isSuccess: lnSuccess,
    isError: lnIsError,
    error: lnError,
    data: lnData,
  } = useGetLnOffertaQuery({ id: idOfferta });

  const { isLoading, isSuccess, isError, error } = useGetBpQuery(
    { entity, ln: lnData | ln | 1 },
    {
      skip: blueprintState.length > 0,
    }
  );

  const countAttivi = entity[pageIndex].contenuti.filter(
    (c) => c.attivo
  ).length;

  if (isLoading) {
    return <div className="form-prodotto">Loading</div>;
  }
  if (countAttivi == 0) {
    return (
      <div className="form-prodotto">
        <form>
          <Toaster titolo="Clicca su un paragrafo per modificare"></Toaster>
        </form>
        <BtnSave></BtnSave>
      </div>
    );
  }
  if (!blueprintState[pageIndex]) {
    //return null;
  }
  return (
    <div className="form-prodotto">
      <form>
        {blueprintState[pageIndex].contenuti.map((c, k) => {
          return (
            <TextInput pageIndex={pageIndex} key={k} chiave={k} item={c} />
          );
        })}
        <BtnSave></BtnSave>
      </form>
    </div>
  );
}
