import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateValore,
  updateVisibilita,
  updateAttivo,
} from "../features/paragrafi/entitySlice";
import parseHTML from "../lib/parseHTML";

export default function RigheTabella({
  pageIndex,
  contenuto,
  className,
  chiave,
}) {
  if (!contenuto.visibile) return null;
  // if (Array.isArray(contenuto.valore)) {
  //   return <>{contenuto.valore}</>;
  // }
  return (
    <>
      {contenuto.valore.map(
        (r, i) =>
          i > 0 && (
            <tr>
              {r.map((c, j) => (
                <td
                  style={{
                    textAlign: j == 2 ? "center" : j == 0 ? "left" : "right",
                  }}
                >
                  {c}
                </td>
              ))}
            </tr>
          )
      )}
    </>
  );
}
