import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectPagina, decrement } from "../features/pagina/paginaSlice";
import {
  selectEntity,
  selectPageCount,
} from "../features/paragrafi/entitySlice";

import FormSegnaposto from "../components/FormSegnaposto";
import PaginaProdottoTpl from "./PaginaProdottoTpl";
import PrimaPaginaTpl from "./PrimaPaginaTpl";
import CondizioniEconomicheTpl from "./CondizioniEconomicheTpl";
import PaginaVuotaTpl from "./PaginaVuotaTpl";
import Testata from "./Testata";
import RiepilogoEconomicoTpl from "./RiepilogoEconomicoTpl";
import ZoomBar from "../components/ZoomBar";

export default function Pagina() {
  const dispatch = useDispatch();
  const entity = useSelector(selectEntity);
  const pageCount = useSelector(selectPageCount);
  let { pageIndex } = useSelector(selectPagina);

  if (pageIndex > pageCount - 1) {
    dispatch(decrement());
  }
  return (
    <div className="scheda-prodotto" style={{ display: "flex" }}>
      <ZoomBar pageIndex={pageIndex} pageCount={pageCount} />
      {entity[pageIndex].template == "Prodotto" && (
        <>
          <PaginaProdottoTpl pageIndex={pageIndex}></PaginaProdottoTpl>
        </>
      )}
      {entity[pageIndex].template == "Testata" && (
        <Testata pageIndex={pageIndex}></Testata>
      )}
      {entity[pageIndex].template == "PrimaPagina" && (
        <PrimaPaginaTpl pageIndex={pageIndex}></PrimaPaginaTpl>
      )}
      {entity[pageIndex].template == "RiepilogoEconomico" && (
        <RiepilogoEconomicoTpl pageIndex={pageIndex}></RiepilogoEconomicoTpl>
      )}
      {entity[pageIndex].template == "CondizioniEconomiche" && (
        <CondizioniEconomicheTpl
          pageIndex={pageIndex}
        ></CondizioniEconomicheTpl>
      )}
      {entity[pageIndex].template == "PaginaVuota" && (
        <PaginaVuotaTpl pageIndex={pageIndex}></PaginaVuotaTpl>
      )}

      <FormSegnaposto></FormSegnaposto>
    </div>
  );
}
