import { createSlice } from "@reduxjs/toolkit";

const initialState = [];
//console.log(JSON.stringify(initialState));
export const entitySlice = createSlice({
  name: "entity",
  initialState: initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateCodiceNavision: (state, action) => {
      const { pageIndex } = action.payload;
      state[pageIndex].codiceNavision = action.payload;
    },
    updateNomeComponente: (state, action) => {
      const { pageIndex, value } = action.payload;
      state[pageIndex].nomeComponente = value;
    },
    updateProprieta: (state, action) => {
      const { pageIndex, key, value, parametro } = action.payload;
      state[pageIndex].contenuti[key].parametri[parametro].valore = value;
    },
    updateVisibilita: (state, action) => {
      const { pageIndex, key, value } = action.payload;
      state[pageIndex].contenuti[key].visibile = value;
    },
    updateValore: (state, action) => {
      const { pageIndex, key, value } = action.payload;
      state[pageIndex].contenuti[key].valore = value;
    },
    updateAttivo: (state, action) => {
      const { pageIndex, key, value } = action.payload;
      state[pageIndex].contenuti.forEach((s) => (s.attivo = false));
      state[pageIndex].contenuti[key].attivo = value;
    },
    insertPage: (state, action) => {
      const { pageAt, bp } = action.payload;
      const pageIndex = parseInt(pageAt) - 1;
      state.splice(pageIndex, 0, bp);
      //return state.entity;
      // state.push(entity);
    },
    removePage: (state, action) => {
      const { pageIndex } = action.payload;

      state.splice(pageIndex, 1);
      //return state.entity;
      // state.push(entity);
    },
    movePage: (state, action) => {
      const { pageIndex, verso } = action.payload;
      var element = state[pageIndex];
      state.splice(pageIndex, 1);
      let toIndex = pageIndex - 1;
      if (verso == "+") {
        toIndex = pageIndex + 1;
      }
      state.splice(toIndex, 0, element);
    },
    initEntity: (state, action) => {
      //console.log(action.payload);
      return action.payload;
    },
    updateImmagine: (state, action) => {
      const { pageIndex, key, value } = action.payload;
      console.log(action.payload);

      state[pageIndex].contenuti[key].valore = value;
    },
  },
});

export const {
  initEntity,
  updateCodiceNavision,
  updateNomeComponente,
  updateProprieta,
  updateVisibilita,
  updateValore,
  updateAttivo,
  insertPage,
  removePage,
  movePage,
  updateImmagine,
} = entitySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectEntity = (state) => state.entity;
export const selectPageCount = (state) => {
  return state.entity.length;
};
export default entitySlice.reducer;
