import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateZoom, selectPagina } from "../features/pagina/paginaSlice";
import { removePage, movePage } from "../features/paragrafi/entitySlice";

const queryParams = new URLSearchParams(window.location.search);
const type = queryParams.get("type");

export default function ZoomBar({ pageIndex, pageCount }) {
  const dispatch = useDispatch();
  const pagina = useSelector(selectPagina);
  return (
    <div className="zoombar">
      <div>
        <i
          style={{ display: "block", color: "#666", fontSize: 15, margin: 10 }}
          className="fa fa-search-minus "
          onClick={() => {
            if (pagina.pageZoom > 1) {
              dispatch(updateZoom(pagina.pageZoom - 0.1));
            }
          }}
        ></i>
      </div>
      <input
        style={{ display: "block", width: 80 }}
        type="range"
        min="1"
        max="1.5"
        step="0.01"
        value={pagina.pageZoom}
        onChange={(e) => dispatch(updateZoom(e.target.value))}
      />
      <div>
        <i
          style={{ display: "block", color: "#666", fontSize: 15, margin: 10 }}
          className="fa fa-search-plus "
          onClick={() => {
            if (pagina.pageZoom < 1.5) {
              dispatch(updateZoom(pagina.pageZoom + 0.1));
            }
          }}
        ></i>
      </div>
      <div>{parseInt(pagina.pageZoom * 100)}%</div>
      {!type && (
        <>
          <div
            style={{
              margin: "0 20px",
              color: pageIndex > 1 ? "black" : "#ccc",
            }}
            onClick={() => {
              if (window.confirm("eliminare pagina?")) {
                dispatch(removePage({ pageIndex }));
              }
            }}
          >
            <i className="fa fa-trash" title="Elimina pagina corrente"></i>
          </div>

          <>
            <div
              style={{ margin: "0 20px" }}
              onClick={() => {
                dispatch(movePage({ pageIndex: pageIndex, verso: "-" }));
              }}
            >
              <i
                className="fa fa-backward"
                title="sposta pagina corrente "
                style={{
                  color: pageIndex > 1 ? "black" : "#ccc",
                }}
              ></i>
            </div>
            <div
              style={{ margin: "0 20px" }}
              onClick={() => {
                dispatch(movePage({ pageIndex: pageIndex, verso: "+" }));
              }}
            >
              <i
                className="fa fa-forward"
                title="sposta pagina corrente"
                style={{
                  color:
                    pageIndex > 0 && pageIndex < pageCount - 1
                      ? "black"
                      : "#ccc",
                }}
              ></i>
            </div>
          </>
        </>
      )}
    </div>
  );
}
