import React, { useEffect } from "react";
import Pagina from "./pageTemplates/Pagina";
import Pdf from "./pageTemplates/Pdf";

import {
  useGetEntityQuery,
  useGetProdottoQuery,
} from "./features/api/apiSlice";
const queryParams = new URLSearchParams(window.location.search);
const idOfferta = queryParams.get("pg");
const type = queryParams.get("type");
const storico = queryParams.get("storico");
const ln = queryParams.get("ln");

let App;

if (type === "blueprint") {
  App = () => {
    const { isLoading, isSuccess, isError, error } = useGetProdottoQuery(
      { id: idOfferta, ln: ln | 1 },
      true
    );

    if (isLoading) return <>Loading ...</>;
    if (isError) return <>{JSON.stringify(error)}</>;
    if (isSuccess) {
      return <div className="App">{<Pagina></Pagina>}</div>;
    }
  };
} else if ((type == "pdf") & (storico == 1)) {
  App = () => {
    const { isLoading, isSuccess, isError, error } = useGetEntityQuery(
      { id: idOfferta, storico: true },
      true
    );

    if (isLoading) return <>Loading ...</>;
    if (isError) return <>{JSON.stringify(error)}</>;
    if (isSuccess) {
      return <div className="Pdf">{<Pdf></Pdf>}</div>;
    }
  };
} else if (type == "pdf") {
  App = () => {
    const { isLoading, isSuccess, isError, error } = useGetEntityQuery(
      { id: idOfferta, storico: false },
      true
    );

    if (isLoading) return <>Loading ...</>;
    if (isError) return <>{JSON.stringify(error)}</>;
    if (isSuccess) {
      return <div className="App">{<Pdf></Pdf>}</div>;
    }
  };
} else {
  App = () => {
    const { isLoading, isSuccess, isError, error } = useGetEntityQuery(
      { id: idOfferta, storico: false },
      true
    );

    if (isLoading) return <>Loading ...</>;
    if (isError) return <>{JSON.stringify(error)}</>;
    if (isSuccess) {
      return <div className="App">{<Pagina></Pagina>}</div>;
    }
  };
}
export default App;
